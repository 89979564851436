import React from "react";
import { isValidDNI } from "ec-dni-validator";
import currency from "currency.js";

import FullScreenLoader from "#components/FullScreenLoader";
import { generarFactura, consultarPersona } from "#lib/Facturacion";
import { obtenerUsuario } from "#lib/Sesion";
import Producto from "#components/Producto";
import { consultarProductos } from "#lib/Productos";

import ModalBuscarProducto from "./components/modals/ModalBuscarProducto";
import ModalRegistroSinExito from "./components/modals/ModalRegistroSinExito";
import ModalRegistroConExito from "./components/modals/ModalRegistroConExito";
import ModalConfirmacion from "./components/modals/ModalConfirmacion";
import ModalSinProductos from "./components/modals/ModalSinProducto";
import ModalWarning from "./components/modals/ModalWarning";
import MontosConsolidados from "./components/MontosConsolidados";
import DatosDelCliente from "./components/DatosDelCliente";
import ModalInfoAdicional from "./components/modals/ModalInfoAdicional";

class NuevaFacturaPage extends React.Component {
  state = {
    form: {
      ambiente: "1",
      registrar_comprador: true,
      comprador_cedula: "",
      comprador_nombres: "",
      comprador_direccion: "",
      comprador_email: "",
      tipo_identificacion: "05",
      productos: [],
      infoAdicional: [],
    },
    form_producto: {
      producto_codigo: "",
      producto_descripcion: "",
      producto_precio: 1.0,
      producto_cantidad: 1,
      producto_descuento: 0,
      producto_impuesto: "IVA 15%",
    },
    cedulaEsValida: false,
    usuarioInfo: null,
    productosRegistrados: [],
    tipo_modal: "",
    modalIsOpen: false,
    modalMensaje: false,
    onCloseModal: () => {},
    cargando: false,
    error: "",
  };

  async componentDidMount() {
    try {
      const { usuario } = await obtenerUsuario();
      const { productos } = await consultarProductos();
      this.setState({
        usuarioInfo: usuario,
        productosRegistrados: productos,
        form: {
          ...this.state.form,
          ambiente: usuario.ambiente,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  agregarProducto = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }

    const {
      producto_codigo,
      producto_descripcion,
      producto_precio,
      producto_cantidad,
      producto_descuento,
      producto_impuesto,
    } = this.state.form_producto;

    if (producto_codigo === "" || producto_descripcion === "") {
      this.handleOpenModal(
        "modal_warning",
        "Debe ingresar el código y descripción para agregar el producto."
      );
      return;
    }

    if (
      this.state.form.productos.filter(
        (prod) => producto_codigo === prod.producto_codigo
      ).length
    ) {
      this.handleOpenModal(
        "modal_warning",
        "No puede ingresar otro producto con un mismo código."
      );
      return;
    }

    this.setState({
      form: {
        ...this.state.form,
        productos: [
          ...this.state.form.productos,
          {
            producto_codigo,
            producto_descripcion,
            producto_precio,
            producto_cantidad,
            producto_descuento,
            producto_impuesto,
          },
        ],
      },
      form_producto: {
        producto_codigo: "",
        producto_descripcion: "",
        producto_precio: 1.0,
        producto_cantidad: 1,
        producto_descuento: 0,
        producto_impuesto: "IVA 15%",
      },
    });

    document.getElementById("producto_codigo").focus();
  };

  buscarProductoRegistrado = () => {
    this.handleOpenModal("modal_buscar_producto_registrado");
  };

  saltarInputEnter = (e, siguienteInputId) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      document.getElementById(siguienteInputId).focus();
    }
  };

  handleOpenModal = (tipo_modal, mensaje = "", onClose = () => {}) => {
    this.setState({
      modalIsOpen: true,
      tipo_modal,
      modalMensaje: mensaje,
      cargando: false,
      onCloseModal: onClose,
    });
  };

  handleCloseModal = () => {
    this.state.onCloseModal();
    this.setState({
      modalIsOpen: false,
      onCloseModal: () => {},
    });
  };

  handleChange = (e, setUpperCase = true) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: setUpperCase
          ? e.target.value.toUpperCase()
          : e.target.value,
      },
    });

    if (e.target.name === "tipo_identificacion") {
      if (e.target.value === "07") {
        this.setState({
          cedulaEsValida: true,
          form: {
            ...this.state.form,
            tipo_identificacion: "07",
            comprador_cedula: "9999999999999",
            comprador_nombres: "CONSUMIDOR FINAL",
            comprador_direccion: "SIN DIRECCION",
          },
        });

        document.getElementById("producto_codigo").focus();
      }
    }
  };

  handleChangeCedula = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
      cedulaEsValida: [10, 13].includes(e.target.value.length),
    });
  };

  handleChangeProducto = (e) => {
    this.setState({
      form_producto: {
        ...this.state.form_producto,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleConsultarPersona = async (e) => {
    try {
      e.preventDefault();

      if (this.state.cedulaEsValida === false) {
        this.handleOpenModal("modal_warning", "La cedula/RUC no es válida.");
        return;
      }

      const { data, mensaje } = await consultarPersona(
        this.state.form.comprador_cedula
      );
      if (!data) {
        this.handleOpenModal("modal_warning", mensaje, () => {
          document.getElementById("comprador_nombres").focus();
        });
      } else {
        this.setState({
          form: {
            ...this.state.form,
            registrar_comprador: false,
            tipo_identificacion: data.tipoIdentificacion,
            comprador_nombres: data.razonSocial,
            comprador_direccion: data.direccion,
            comprador_email: data.email || "",
          },
        });

        document.getElementById("producto_codigo").focus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.cedulaEsValida === false) {
      this.handleOpenModal("modal_warning", "La cedula/RUC no es válida.");
      return;
    }

    if (this.state.form.productos.length === 0) {
      this.handleOpenModal("sin_productos");
    } else {
      this.handleOpenModal("enviar_factura");
    }
  };

  handleSeleccionarProductoRegistrado = ({
    codigo,
    descripcion,
    precio,
    impuesto,
  }) => {
    this.setState({
      form_producto: {
        ...this.state.form_producto,
        producto_codigo: codigo,
        producto_descripcion: descripcion,
        producto_precio: precio,
        producto_impuesto: impuesto || "IVA 12%",
      },
    });
  };

  handleActualizarInfoAdicional = (newInfoAdicional) => {
    this.setState({
      form: {
        ...this.state.form,
        infoAdicional: newInfoAdicional,
      },
      modalIsOpen: false,
    });
  };

  handleDelete = (index) => {
    const productos = [...this.state.form.productos];

    productos.splice(index, 1);

    this.setState({
      form: {
        ...this.state.form,
        productos,
      },
    });
  };

  enviarFactura = async () => {
    this.setState({
      cargando: true,
      error: "",
      modalIsOpen: false,
    });

    const respuesta = await generarFactura(this.state.form);

    if (respuesta.SRI?.estado === "RECIBIDA") {
      this.handleOpenModal("factura_recibida");
      setTimeout(() => {
        this.limpiar();
      }, 0);
    } else {
      console.log(respuesta);
      this.handleOpenModal("factura_no_recibida");
    }
  };

  mostrarProductos = () =>
    this.state.form.productos.map((producto, index) => (
      <Producto
        key={producto.producto_codigo}
        index={index}
        producto={producto}
        onDelete={this.handleDelete}
      />
    ));

  limpiar = () => {
    this.setState({
      form: {
        ...this.state.form,
        registrar_comprador: true,
        comprador_cedula: "",
        comprador_nombres: "",
        comprador_direccion: "",
        comprador_email: "",
        tipo_identificacion: "05",
        productos: [],
      },
    });
  };

  render() {
    const { productos } = this.state.form;
    const {
      producto_codigo,
      producto_descripcion,
      producto_precio,
      producto_cantidad,
      producto_descuento,
      producto_impuesto,
    } = this.state.form_producto;

    if (!this.state.usuarioInfo) {
      return <FullScreenLoader />;
    }

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className="mx-auto container relative"
      >
        {this.state.cargando && <FullScreenLoader />}
        <div>
          <h2 className="font-bold my-2 text-lg xl:text-xl">Nueva Factura</h2>
          <h2 className="font-bold my-2 text-lg xl:text-xl">
            {this.state.usuarioInfo.nombreComercial}
          </h2>
        </div>
        <div className="flex flex-row">
          <DatosDelCliente
            state={this.state}
            handleChange={this.handleChange}
            handleChangeCedula={this.handleChangeCedula}
            handleConsultarPersona={this.handleConsultarPersona}
          />
          <MontosConsolidados productos={productos} />
        </div>
        <div className="p-2 flex flex-row font-bold text-lg text-custom-color-1">
          <h2>Lista de items</h2>
        </div>
        <div className="flex flex-row">
          <div className="w-20 md:w-20 lg:w-32 mx-1 font-bold">Código</div>
          <div className="w-28 md:w-40 lg:w-64 mx-1 font-bold">Descripcion</div>
          <div className="w-14 md:w-20 lg:w-32 mx-1 font-bold">Precio</div>
          <div className="w-14 md:w-20 lg:w-32 mx-1 font-bold truncate">
            Cantidad
          </div>
          <div className="w-14 md:w-20 lg:w-32 mx-1 font-bold truncate">
            Descuento
          </div>
          <div className="w-20 md:w-20 lg:w-32 mx-1 font-bold">Impuesto</div>
          <div className="w-20 md:w-20 lg:w-32 mx-1 font-bold">Subtotal</div>
        </div>
        {this.mostrarProductos()}
        <div className="flex flex-row my-1">
          <input
            onKeyDown={(e) => {
              const producto = this.state.productosRegistrados.find(
                (prod) =>
                  prod.codigo === this.state.form_producto.producto_codigo
              );
              if (producto) this.handleSeleccionarProductoRegistrado(producto);
              this.saltarInputEnter(e, "producto_descripcion");
            }}
            type="text"
            id="producto_codigo"
            name="producto_codigo"
            onChange={this.handleChangeProducto}
            value={producto_codigo}
            className="w-20 md:w-20 lg:w-32 mx-1 border-2"
          />
          <input
            onKeyDown={(e) => this.saltarInputEnter(e, "producto_precio")}
            type="text"
            id="producto_descripcion"
            name="producto_descripcion"
            onChange={this.handleChangeProducto}
            value={producto_descripcion}
            className="w-28 md:w-40 lg:w-64 mx-1 border-2"
          />
          <input
            onKeyDown={(e) => this.saltarInputEnter(e, "producto_cantidad")}
            type="number"
            id="producto_precio"
            name="producto_precio"
            onChange={this.handleChangeProducto}
            value={producto_precio}
            className="w-14 md:w-20 lg:w-32 mx-1 border-2 text-right"
            pattern="^\d*(\.\d{0,2})?$"
            step="0.05"
            min={0}
          />
          <input
            onKeyDown={(e) => this.saltarInputEnter(e, "producto_descuento")}
            type="number"
            id="producto_cantidad"
            name="producto_cantidad"
            onChange={this.handleChangeProducto}
            value={producto_cantidad}
            className="w-14 md:w-20 lg:w-32 mx-1 border-2 text-right"
            pattern="^\d*(\.\d{0,2})?$"
            step="1"
            min={1}
          />
          <input
            onKeyDown={(e) => this.saltarInputEnter(e, "producto_impuesto")}
            type="number"
            id="producto_descuento"
            name="producto_descuento"
            onChange={this.handleChangeProducto}
            value={producto_descuento}
            className="w-14 md:w-20 lg:w-32 mx-1 border-2 text-right"
            pattern="^\d*(\.\d{0,2})?$"
            step="0.05"
            min={0}
          />
          <select
            onKeyDown={this.agregarProducto}
            id="producto_impuesto"
            name="producto_impuesto"
            onChange={this.handleChangeProducto}
            value={producto_impuesto}
            className="w-20 md:w-20 lg:w-32 mx-1 border-2"
          >
            <option value="IVA 15%" className="">
              IVA 15%
            </option>
            {/* <option value="IVA 12%" className="">
              IVA 12%
            </option> */}
            <option value="IVA 0%" className="">
              IVA 0%
            </option>
          </select>
          <input
            type="number"
            value={
              currency(producto_precio)
                .multiply(producto_cantidad)
                .subtract(producto_descuento).value
            }
            className="w-20 md:w-20 lg:w-32 mx-1 border-2 text-right"
            disabled
          />
        </div>
        <div className="flex flex-row justify-between my-2">
          <div className="flex flex-row">
            <div
              onClick={this.buscarProductoRegistrado}
              className="cursor-pointer mx-1 p-2 bg-green-500 hover:bg-green-400 text-white rounded-md"
            >
              Buscar producto <i className="fas fa-search"></i>
            </div>
            <div
              onClick={this.agregarProducto}
              className="cursor-pointer mx-1 p-2 bg-blue-600 hover:bg-blue-500 text-white rounded-md"
            >
              Agregar <i className="fas fa-plus-circle"></i>
            </div>
          </div>
          <div
            className="cursor-pointer mx-1 mr-1 p-2 bg-yellow-600 hover:bg-yellow-500 text-white rounded-md"
            onClick={() => this.handleOpenModal("modal_info_adicional")}
          >
            Agregar información adicional <i className="fas fa-plus-circle"></i>
          </div>
        </div>
        <button className="bg-green-500 hover:bg-green-400 rounded-lg p-2 w-64 text-white font-bold mt-4">
          Facturar
        </button>
        <ModalSinProductos
          isOpen={
            this.state.modalIsOpen && this.state.tipo_modal === "sin_productos"
          }
          onClose={this.handleCloseModal}
        />
        <ModalConfirmacion
          isOpen={
            this.state.modalIsOpen && this.state.tipo_modal === "enviar_factura"
          }
          onClose={this.handleCloseModal}
          onConfirm={this.enviarFactura}
          state={this.state}
        />
        <ModalRegistroConExito
          isOpen={
            this.state.modalIsOpen &&
            this.state.tipo_modal === "factura_recibida"
          }
          onClose={this.handleCloseModal}
          onConfirm={this.enviarFactura}
          state={this.state}
        />
        <ModalRegistroSinExito
          isOpen={
            this.state.modalIsOpen &&
            this.state.tipo_modal === "factura_no_recibida"
          }
          onClose={this.handleCloseModal}
        />
        <ModalWarning
          isOpen={
            this.state.modalIsOpen && this.state.tipo_modal === "modal_warning"
          }
          onClose={this.handleCloseModal}
          mensaje={this.state.modalMensaje}
        />
        <ModalBuscarProducto
          isOpen={
            this.state.modalIsOpen &&
            this.state.tipo_modal === "modal_buscar_producto_registrado"
          }
          onClose={this.handleCloseModal}
          onClick={this.handleSeleccionarProductoRegistrado}
          productos={this.state.productosRegistrados}
        />
        {this.state.modalIsOpen &&
          this.state.tipo_modal === "modal_info_adicional" && (
            <ModalInfoAdicional
              isOpen={
                this.state.modalIsOpen &&
                this.state.tipo_modal === "modal_info_adicional"
              }
              onClose={this.handleCloseModal}
              onClick={this.handleActualizarInfoAdicional}
              infoAdicional={this.state.form.infoAdicional}
            />
          )}
      </form>
    );
  }
}

export default NuevaFacturaPage;
